import React, { useState } from 'react';
import '../styles/Progress.css';

const Progress = ({ userData }) => {
  const modules = [
    {
      moduleTitle: 'Module 1: HTML/CSS',
      weeks: [
        {
          weekTitle: 'Week 1',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Lesson 1 - Web Development Terminologies', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-1/lesson-1.md' },
                { title: 'Lesson 2 - Visual Studio Code (VS Code)', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-1/lesson-2.md' },
                { title: 'Lesson 3 - Install and Customize Visual Studio Code [Activity]', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-1/lesson-3.md' },
                { title: 'Lesson 4 - Optimizing Your Camera Position for Remote Collaboration', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-1/lesson-4.md' },
                { title: 'Lesson 5 - Understanding HTML Semantics', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-1/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 2',
              lessons: [
                { title: 'Lesson 1 - Git and GitHub', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-2/lesson-1.md' },
                { title: 'Lesson 2 - Mastering GitFlow', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-2/lesson-2.md' },
                { title: 'Lesson 3 - Create an HTML and CSS File [Activity]', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-2/lesson-3.md' },
                { title: 'Lesson 4 - The CSS Box Model', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-2/lesson-4.md' },
                { title: 'Lesson 5 - Mastering Flexbox for Layouts', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-2/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 3',
              lessons: [
                { title: 'Lesson 1 - Utilizing Chrome Developer Tools', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-3/lesson-1.md' },
                { title: 'Lesson 2 - Mastering Relative and Absolute Sizes in Web Design', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-3/lesson-2.md' },
                { title: 'Lesson 3 - Responsive Web Design', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-3/lesson-3.md' },
                { title: 'Lesson 4 - Using Figma to Build Responsive Websites', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-3/lesson-4.md' },
                { title: 'Lesson 5 - Design a Travel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-3/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 4',
              lessons: [
                { title: 'Lesson 1 - Design a Travel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-4/lesson-1.md' },
              ],
            },
            {
              dayTitle: 'Day 5',
              lessons: [
                { title: 'Lesson 1 - Design a Travel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-1/day-5lesson-1.md' },
              ],
            },
          ],
        },
        {
          weekTitle: 'Week 2',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Lesson 1 - Adding Background Pictures or Videos to a Website', link: 'https://github.com/elevateHER/module-1/blob/main/week-2/day-1/lesson-1.md' },
                { title: 'Lesson 2 - Mastering CSS Positioning', link: 'https://github.com/elevateHER/module-1/blob/main/week-2/day-1/lesson-2.md' },
                { title: 'Lesson 3 - Deployment', link: 'https://github.com/elevateHER/module-1/blob/main/week-2/day-1/lesson-3.md' },
                { title: 'Lesson 4 - README File', link: 'https://github.com/elevateHER/module-1/blob/main/week-2/day-1/lesson-4.md' },
                { title: 'Lesson 5 - Introduction to SASS: Supercharging Your CSS', link: 'https://github.com/elevateHER/module-1/blob/main/week-2/day-1/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 2',
              lessons: [
                { title: 'Lesson 1 - Design a Travel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-2/day-2/lesson-1.md' },
              ],
            },
            {
              dayTitle: 'Day 3',
              lessons: [
                { title: 'Lesson 1 - Design a Travel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-2/day-3/lesson-1.md' },
              ],
            },
            {
              dayTitle: 'Day 4',
              lessons: [
                { title: 'Lesson 1 - Design a Travel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-2/day-4/lesson-1.md' },
              ],
            },
            {
              dayTitle: 'Day 5',
              lessons: [
                { title: 'Lesson 1 - Design a Travel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-2/day-5/lesson-1.md' },
              ],
            },
          ],
        },
        {
          weekTitle: 'Week 3',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Lesson 1 - Introduction to Bootstrap', link: 'https://github.com/elevateHER/module-1/blob/main/week-3/day-1/lesson-1.md' },
                { title: 'Lesson 2 - Introduction to Tailwind CSS', link: 'https://github.com/elevateHER/module-1/blob/main/week-3/day-1/lesson-2.md' },
                { title: 'Lesson 3 - Bootstrap vs. Tailwind CSS', link: 'https://github.com/elevateHER/module-1/blob/main/week-3/day-1/lesson-3.md' },
                { title: 'Lesson 4 - CSS Transitions and Animations', link: 'https://github.com/elevateHER/module-1/blob/main/week-3/day-1/lesson-4.md' },
                { title: 'Lesson 5 - CSS Shadow Effects', link: 'https://github.com/elevateHER/module-1/blob/main/week-3/day-1/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 2',
              lessons: [
                { title: 'Lesson 1 - Design a Travel Website (Desktop View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-3/day-2/lesson-1.md' },
              ],
            },
            {
              dayTitle: 'Day 3',
              lessons: [
                { title: 'Lesson 1 - Design a Travel Website (Desktop View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-3/day-3/lesson-1.md' },
              ],
            },
            {
              dayTitle: 'Day 4',
              lessons: [
                { title: 'Lesson 1 - Design a Travel Website (Desktop View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-3/day-4/lesson-1.md' },
              ],
            },
            {
              dayTitle: 'Day 5',
              lessons: [
                { title: 'Lesson 1 - Design a Travel Website (Desktop View) [Project]', link: 'https://github.com/elevateHER/module-1/blob/main/week-3/day-5/lesson-1.md' },
              ],
            },
          ],
        },
        {
          weekTitle: 'Week 4',
          days: [
            {
              dayTitle: 'Design a Travel Website (Mobile & Desktop View Fully Responsive) [Project]',
              lessons: [
                { title: 'Week 4 has been reserved for project completion. You are expected to get your project approved before 11:59PM on day 5 to pass the Module.' },
              ],
            },          
          ],
        },
        // Add other weeks as needed
      ],
    },
    {
      moduleTitle: 'Module 2: JavaScript',
      weeks: [
        {
          weekTitle: 'Week 1',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Lesson 1 - Linking JavaScript Files to HTML', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-1/lesson-1.md' },
                { title: 'Lesson 2 - Using Console in Chrome Developer Tools', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-1/lesson-2.md' },
                { title: 'Lesson 3 - Understanding Functions in JavaScript', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-1/lesson-3.md' },
                { title: 'Lesson 4 - Understanding Arrow Functions in JavaScript', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-1/lesson-4.md' },
                { title: 'Lesson 5 - Mastering map, filter, and reduce Methods in JavaScript', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-1/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 2',
              lessons: [
                { title: 'Lesson 1 - Understanding the JavaScript Document Object Model (DOM)', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-2/lesson-1.md' },
                { title: 'Lesson 2 - Understanding Events in JavaScript', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-2/lesson-2.md' },
                { title: 'Lesson 3 - Dynamically Rendering Content using JavaScript', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-2/lesson-3.md' },
                { title: 'Lesson 4 - Adding a Hamburger Menu for Mobile View', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-2/lesson-4.md' },
                { title: 'Lesson 5 - Hotel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-2/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 3',
              lessons: [
                { title: 'Lesson 1 - Hotel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-3/lesson-1.md' }
              ],
            },
            {
              dayTitle: 'Day 4',
              lessons: [
                { title: 'Lesson 1 - Hotel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-4/lesson-1.md' }
              ],
            },
            {
              dayTitle: 'Day 5',
              lessons: [
                { title: 'Lesson 1 - Hotel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-2/blob/main/week-1/day-5/lesson-1.md' }
              ],
            },
          ],
        },
        {
          weekTitle: 'Week 2',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Lesson 1 - Hotel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-1/lesson-1.md' }
              ],
            },
            {
              dayTitle: 'Day 2',
              lessons: [
                { title: 'Lesson 1 - Hotel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-2/lesson-1.md' }
              ],
            },
            {
              dayTitle: 'Day 3',
              lessons: [
                { title: 'Lesson 1 - Hotel Website (Mobile View) [Project]', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-3/lesson-1.md' }
              ],
            },
            {
              dayTitle: 'Day 4',
              lessons: [
                { title: 'Lesson 1 - Understanding APIs', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-4/lesson-1.md' },
                { title: 'Lesson 2 - Understanding Synchronous and Asynchronous JavaScript ', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-4/lesson-2.md' },
                { title: 'Lesson 3 - Understanding HTTP Requests', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-4/lesson-3.md' },
                { title: 'Lesson 4 - Methods of Fetching APIs', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-4/lesson-4.md' },
                { title: 'Lesson 5 - Understanding async and await in JavaScript', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-4/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 5',
              lessons: [
                { title: 'Lesson 1 - Fetching and Displaying Data from an API using Fetch API', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-5/lesson-1.md' },
                { title: 'Lesson 2 - Using Postman to Test API Endpoints', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-5/lesson-2.md' },
                { title: 'Lesson 3 - Recipe Website (Responsive) [Project]', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-5/lesson-3.md' },
                { title: 'Lesson 4 - Recipe Website (Responsive) [Project]', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-5/lesson-4.md' },
                { title: 'Lesson 5 - Recipe Website (Responsive) [Project]', link: 'https://github.com/elevateHER/module-2/blob/main/week-2/day-5/lesson-5.md' },
              ],
            },
          ],
        },
        {
          weekTitle: 'Week 3',
          days: [
            {
              dayTitle: 'Recipe Website (Responsive) [Project]',
              lessons: [
                { title: 'Week 3 has been reserved for project completion.'}
              ],
            }
          ],
        },
        {
          weekTitle: 'Week 4',
          days: [
            {
              dayTitle: 'Recipe Website (Responsive) [Project]',
              lessons: [
                { title: 'Week 4 has been reserved for project completion. You are expected to get your project approved before 11:59PM on day 5 to pass the Module.'}
              ],
            }
          ],
        }
        // Add other weeks as needed!
      ],
    },
    {
      moduleTitle: 'Module 3: React',
      weeks: [
        {
          weekTitle: 'Week 1',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Lesson 1 - Introduction to React', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-1/lesson-1.md' },
                { title: 'Lesson 2 - Understanding Single Page Applications (SPA)', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-1/lesson-2.md' },
                { title: 'Lesson 3 - How to Create a React App', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-1/lesson-3.md' },
                { title: 'Lesson 4 - Components in React', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-1/lesson-4.md' },
                { title: 'Lesson 5 - Styling a React App', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-1/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 2',
              lessons: [
                { title: 'Lesson 1 - Handling Click Events in React', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-2/lesson-1.md' },
                { title: 'Lesson 2 - Managing State with the useState Hook', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-2/lesson-2.md' },
                { title: 'Lesson 3 - Passing Data and Functions with Props in React', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-2/lesson-3.md' },
                { title: 'Lesson 4 - Understanding the useEffect Hook', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-2/lesson-4.md' },
                { title: 'Lesson 5 - Fetching API Data with useEffect, Handling Loading State, and Error Handling', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-2/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 3',
              lessons: [
                { title: 'Lesson 1 - Navigating Your React App with React Router', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-3/lesson-1.md' },
                { title: 'Lesson 2 - Controlled Inputs and Form Submission', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-3/lesson-2.md' },
                { title: 'Lesson 3 - Understanding .gitignore', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-3/lesson-3.md' },
                { title: 'Lesson 4 - Using Axios with React', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-3/lesson-4.md' },
                { title: 'Lesson 5 - Using React Context API', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-3/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 4',
              lessons: [
                { title: 'Lesson 1 - Startup Website I (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-4/lesson-1.md' }
              ],
            },
            {
              dayTitle: 'Day 5',
              lessons: [
                { title: 'Lesson 1 - Startup Website I (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-1/day-5/lesson-1.md' }
              ],
            },
          ],
        },
        {
          weekTitle: 'Week 2',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Lesson 1 - Startup Website I (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-2/day-1/lesson-1.md' }
              ],
            },
            {
              dayTitle: 'Day 2',
              lessons: [
                { title: 'Lesson 1 - Startup Website I (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-2/day-1/lesson-2.md' }
              ],
            },
            {
              dayTitle: 'Day 3',
              lessons: [
                { title: 'Lesson 1 - Startup Website I (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-2/day-1/lesson-3.md' }
              ],
            },
            {
              dayTitle: 'Day 4',
              lessons: [
                { title: 'Lesson 1 - Startup Website I (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-2/day-1/lesson-4.md' }                
              ],
            },
            {
              dayTitle: 'Day 5',
              lessons: [
                { title: 'Lesson 1 - Startup Website I (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-2/day-1/lesson-5.md' }                
              ],
            },
          ],
        },
        {
          weekTitle: 'Week 3',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Lesson 1 - Understanding Redux and Redux Toolkit', link: 'https://github.com/elevateHER/module-3/blob/main/week-3/day-1/lesson-1.md' },
                { title: 'Lesson 2 - The Advantages of Adding Animation to a Website', link: 'https://github.com/elevateHER/module-3/blob/main/week-3/day-1/lesson-2.md' },
                { title: 'Lesson 3 - Using Framer Motion for Animations', link: 'https://github.com/elevateHER/module-3/blob/main/week-3/day-1/lesson-3.md' },
                { title: 'Lesson 4 - Testing React Components using Jest', link: 'https://github.com/elevateHER/module-3/blob/main/week-3/day-1/lesson-4.md' },
                { title: 'Lesson 5 - Startup Website II (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-3/day-1/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 2',
              lessons: [
                { title: 'Lesson 1 - Startup Website II (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-2/day-1/lesson-5.md' }                
              ],
            },
            {
              dayTitle: 'Day 3',
              lessons: [
                { title: 'Lesson 1 - Startup Website II (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-2/day-1/lesson-5.md' }                
              ],
            },
            {
              dayTitle: 'Day 4',
              lessons: [
                { title: 'Lesson 1 - Startup Website II (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-2/day-1/lesson-5.md' }                
              ],
            },
            {
              dayTitle: 'Day 5',
              lessons: [
                { title: 'Lesson 1 - Startup Website II (Responsive) [Project]', link: 'https://github.com/elevateHER/module-3/blob/main/week-2/day-1/lesson-5.md' }                
              ],
            },
          ],
        },
        {
          weekTitle: 'Week 4',
          days: [
            {
              dayTitle: 'Startup Website II (Responsive) [Project]',
              lessons: [
                { title: 'Week 4 has been reserved for project completion. You are expected to get your project approved before 11:59PM on day 5 to pass the Module.'}
              ],
            }
          ],
        }
        // Add other weeks as needed!
      ],
    },
    {
      moduleTitle: 'Module 4: Node.js/MongoDB',
      weeks: [
        {
          weekTitle: 'Week 1',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Lesson 1 - What is Backend in Web Development?', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-1/lesson-1.md' },
                { title: 'Lesson 2 - What is a Database?', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-1/lesson-2.md' },
                { title: 'Lesson 3 - Introduction to MongoDB', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-1/lesson-3.md' },
                { title: 'Lesson 4 - What is Node.js?', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-1/lesson-4.md' },
                { title: 'Lesson 5 - Using "require" and "module.exports"', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-1/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 2',
              lessons: [
                { title: 'Lesson 1 - Introduction to REST APIs and HTTP Methods', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-2/lesson-1.md' },
                { title: 'Lesson 2 - Understanding HTTP Methods and API Routes', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-2/lesson-2.md' },
                { title: 'Lesson 3 - Introduction to Express.js and Setting It Up', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-2/lesson-3.md' },
                { title: 'Lesson 4 - Introduction to Middleware in Express.js', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-2/lesson-4.md' },
                { title: 'Lesson 5 - Using express.Router()', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-2/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 3',
              lessons: [
                { title: 'Lesson 1 - Using Mongoose with MongoDB Atlas', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-3/lesson-1.md' },
                { title: 'Lesson 2 - Models and Schemas', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-3/lesson-2.md' },
                { title: 'Lesson 3 - Understanding Controllers', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-3/lesson-3.md' },
                { title: 'Lesson 4 - Building a Full-Stack MERN Application', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-3/lesson-4.md' },
                { title: 'Lesson 5 - Implementing Authentication in a MERN Stack Application', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-3/lesson-5.md' },
              ],
            },
            {
              dayTitle: 'Day 4',
              lessons: [
                { title: 'Lesson 1 - Auth Login with Passport.js (OPTIONAL LESSON)', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-4/lesson-1.md' },
                { title: 'Lesson 2 - OTP Verification and Forgot Password Implementation (OPTIONAL LESSON)', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-4/lesson-2.md' },
                { title: 'Lesson 3 - Introduction to Firebase (OPTIONAL LESSON)', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-4/lesson-3.md' },
                { title: 'Lesson 4 - Redis (OPTIONAL LESSON)', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-4/lesson-4.md' },
                { title: 'Lesson 5 - Introduction to GraphQL (OPTIONAL LESSON)', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-4/lesson-5.md' }
              ],
            },
            {
              dayTitle: 'Day 5',
              lessons: [
                { title: 'Lesson 1 - Flower Delivery Website (Milestone 1) [Project]', link: 'https://github.com/elevateHER/module-4/blob/main/week-1/day-5/lesson-1.md' }
              ],
            },
          ],
        },
        {
          weekTitle: 'Week 2',
          days: [
            {
              dayTitle: 'Flower Delivery Website (Milestone 1) [Project]',
              lessons: [
                { title: 'Week 2 has been reserved for Milestone 1 completion.'}
              ],
            }
          ],
        },
        {
          weekTitle: 'Week 3',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Lesson 1 - Flower Delivery Website (Milestone 2) [Project]', link: 'https://github.com/elevateHER/module-4/blob/main/week-3/day-1/lesson-1.md' }
              ],
            }
          ],
        },
        {
          weekTitle: 'Week 4',
          days: [
            {
              dayTitle: 'Flower Delivery Website (Milestone 2) [Project]',
              lessons: [
                { title: 'Week 4 has been reserved for Milestone 2 completion. You are expected to get your project approved before 11:59PM on day 5 to pass the Module.'}
              ],
            }
          ],
        }
        // Add other weeks as needed!
      ],
    },
    {
      moduleTitle: 'Module 5: Full-stack App',
      weeks: [
        {
          weekTitle: 'Week 1',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Project: Flower Delivery Website [Milestone 3] (Frontend - Admin Panel)', link: 'https://github.com/elevateHER/module-5/blob/main/week-1/day-1/lesson-1.md' }               
              ],
            }           
          ],
        },
        {
          weekTitle: 'Week 2',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Project: Flower Delivery Website [Milestone 4] (Frontend - Main App)', link: 'https://github.com/elevateHER/module-5/blob/main/week-2/day-1/lesson-1.md' }               
              ],
            }           
          ],
        },
        {
          weekTitle: 'Week 3',
          days: [
            {
              dayTitle: 'Day 1',
              lessons: [
                { title: 'Project: Flower Delivery Website [Milestone 5] (Full-stack)', link: 'https://github.com/elevateHER/module-5/blob/main/week-3/day-1/lesson-1.md' }               
              ],
            }           
          ],
        },
        {
          weekTitle: 'Week 4',
          days: [
            {
              dayTitle: 'Project: Flower Delivery Website [Milestone 5] (FUll-stack)',
              lessons: [
                { title: 'Week 4 has been reserved for Milestone 2 completion. You are expected to get your project approved before 11:59PM on day 5 to pass the Module.'}
              ],
            }
          ],
        }
        // Add other weeks as needed
      ],
    }
    
    // Add other modules as needed!
  ];

  const [openWeeks, setOpenWeeks] = useState({});

  const toggleWeek = (moduleIndex, weekIndex) => {
    const key = `${moduleIndex}-${weekIndex}`;
    setOpenWeeks((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const getVisibleModules = () => {
    return modules.slice(0, userData.module);
  };

  return (
    <>
      <div className='dashboard-component progress-back'>
        <div className="dashboard-overlay"></div>
        <div className='dashboard-component-heading'>
          <p className='dashboard-component-heading bold'>Progress</p>
        </div>
        <div style={{backgroundColor: "#f3f3fb", paddingBottom: "40px"}}>
          {getVisibleModules().map((module, moduleIndex) => (
            <div key={moduleIndex} style={{marginTop: "40px"}}>
              <p className='bold progress-title'>{module.moduleTitle}</p>
              {module.weeks.map((week, weekIndex) => (
                <div key={weekIndex}>
                  <p className='progress-item row space-between' onClick={() => toggleWeek(moduleIndex, weekIndex)} style={{ cursor: 'pointer' }}>
                    <span className='purple bold size4'>{week.weekTitle}</span>
                    <i className={`fa-solid fa-angle-right ${openWeeks[`${moduleIndex}-${weekIndex}`] ? 'open' : ''} purple bold`}></i>
                  </p>
                  <div className={`collapsible-content ${openWeeks[`${moduleIndex}-${weekIndex}`] ? 'open' : ''}`}>
                    {week.days.map((day, dayIndex) => (
                      <div key={dayIndex}>
                        <p className='bold size4 caps lesson-day'>{day.dayTitle}</p>
                        <ul>
                          {day.lessons.map((lesson, lessonIndex) => (
                            <li key={lessonIndex}><a href={lesson.link} target='_blank'>{lesson.title}</a></li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Progress;
